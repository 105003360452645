import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import Lien from "../../composants/navigation/Lien"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePhotographierAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "photographierAuroresBoreales" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "comprendreAuroresBoreales"
            "guideAuroresBoreales"
            "galerieAuroresBoreales"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["auroreHvitserkur", "auroreTaiga"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesMateriel"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPortraitMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["auroresBorealesLephareDAndenes"] } }
    ) {
      ...FragmentImagePortraitMoyen
    }
  }
`

export default function PhotographierAuroresBoreales() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePhotographierAuroresBoreales")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPortraitMoyen"
  )

  /*nécessaire si le type JSONLD de la page est défini comme étant "FAQ"*/
  page.jsonLDFAQ = [
    {
      question: "Comment s’équiper ?",
      reponse:
        "Photographier les aurores est une activité statique : on ne bouge pas beaucoup pendant plusieurs heures. Vous ne profiterez donc pas du spectacle si le froid vous gagne trop vite. Vous devez vous équiper plus chaudement qu’un observateur classique qui rentrera plus fréquemment au chaud ou « abandonnera son poste » plus tôt dans la nuit.",
    },
    {
      question: "Quel appareil photo utiliser ?",
      reponse:
        "N’importe quel appareil permettant de faire des poses suffisamment longues peut faire l’affaire. Mais pour ce sujet très exigeant, un appareil réflex ou hybride sera plus performant car souvent moins bruité dans les hautes sensibilités.",
    },
    {
      question: "Quel objectif choisir ?",
      reponse:
        "Un objectif grand angle est idéal. Compte-tenu de la rapidité du sujet, votre objectif doit ouvrir au minimum F2.8. Un objectif à F4 peut convenir, mais les draperies des aurores ne seront pas figées : vous devrez faire des poses plus longues pour compenser le manque de luminosité de l’objectif.",
    },
    {
      question: "Quels accessoires ?",
      reponse:
        "Un trépied est indispensable, ainsi qu’une télécommande pour ne pas faire vibrer l’appareil lors du déclenchement (utilisez le retardateur sinon). Pour les panoramas, une rotule panoramique vous sera utile. Bien sûr, n’oubliez pas une deuxième batterie, votre lampe frontale (avec un éclairage rouge pour ne pas vous éblouir).",
    },
    {
      question: "Quels réglages utiliser ?",
      reponse:
        "Vérifiez que votre boîtier est en mode RAW. Sinon, la compression des images va détruire les étoiles les plus faibles et votre photo perdra des détails. Ouvrez votre objectif au maximum. Déterminez ensuite le réglage ISO : sur mon EOS 6D, je reste la plupart du temps à 3200 ISO. Dernier réglage : le temps de pose. Comme les aurores boréales peuvent être très brillantes, et sont soit lentes soit rapides, les temps d’expositions à utiliser varient fortement. De 2 secondes pour éviter une surexposition à plus de 10 secondes.",
    },
    {
      question: "Comment faire la mise au point ?",
      reponse:
        "Le meilleur moyen est de viser une étoile brillante et de la grossir au maximum avec le liveview. Faites ensuite la mise au point manuellement : l’étoile doit devenir aussi petite que possible.",
    },
    {
      question: "Que faire pendant la nuit ?",
      reponse:
        "Restez aux aguets d’environ 19h jusqu’à au moins minuit (v. « guide d’observation des aurores boréales ». Plusieurs ondes de choc devraient se produire : l’aurore boréale va perdre de l’activité, puis va en regagner. Dites-vous qu’elles sont capricieuses : il suffit que l’on souhaite partir parce qu’il n’y a plus d’activité pour que l’aurore se réveille...",
    },
    {
      question: "Où se rendre ?",
      reponse:
        "Toutes les régions situées sous le cercle auroral (c’est-à-dire à la latitude 60° - 70° Nord) sont idéales. Pour réaliser de belles photographies d’aurores boréales, vous devrez ensuite à tout prix vous éloigner des villes et de leur pollution lumineuse. En cas d’activité normale, les aurores apparaîtront sur les horizons Nord, de l’Ouest à l’Est, ou au zénith.",
    },
  ]

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Préambule">
        <p>
          Pour aboutir à de bons résultats photographiques, il est indispensable
          de bien comprendre le phénomène. Je vous invite donc à lire avant ces
          2 articles si ce n’est déjà fait :
        </p>
        <ul>
          <li>
            <Lien codePageCible="comprendreAuroresBoreales">
              Comprendre les aurores boréales
            </Lien>
            .
          </li>
          <li>
            <Lien codePageCible="guideAuroresBoreales">
              Guide d’observation des aurores boréales
            </Lien>{" "}
            (préparation du voyage, lecture des prévisions de tempêtes solaires,
            liens utiles…).
          </li>
        </ul>
      </Section>
      <Section titre="Comment s’équiper ?">
        <p>
          Photographier les aurores est une activité statique :{" "}
          <strong>on ne bouge pas beaucoup pendant plusieurs heures</strong>.
          Vous ne profiterez donc pas du spectacle si le froid vous gagne trop
          vite. Vous devez vous équiper <strong>plus chaudement</strong> qu’un
          observateur classique qui rentrera plus fréquemment au chaud ou «
          abandonnera son poste » plus tôt dans la nuit.
        </p>
        <h3>Pour le corps</h3>
        <p>
          Un bon équipement de ski suffit. En cas de froid intense, vous
          multiplierez les couches : sous-vêtements de ski (vous en trouvez pour
          30€ chez Décathlon), polaires diverses, caleçon de ski chaud.
        </p>
        <h3>Pour les extrémités</h3>
        <p>
          Vous devez investir pour couvrir les{" "}
          <strong>extrémités de votre corps</strong>. C’est en effet par là que
          se produisent les plus grandes déperditions de chaleur de votre corps.
        </p>
        <ul>
          <li>
            <strong>Tête</strong> : investissez dans un gros bonnet ou une
            chapka.
          </li>
          <li>
            <strong>Mains</strong> : protégées par une paire de moufles chaudes,
            ou des gants de ski couverts de sur-gants (10 € chez Décathlon) dans
            lesquels vous glisserez une chaufferette. Prenez également des
            sous-gants : ils vous protégeront du froid quand vous aurez enlevé
            vos gants pour manipuler votre appareil photo.
          </li>
          <li>
            <strong>Pieds</strong> : c’est par là que vous sentirez le froid
            vous envahir. Évitez donc vraiment les chaussures de randonnées
            d’été : elles ne sont pas suffisamment chaudes et résistantes à
            l’humidité très froide de la neige. Investissez plutôt dans une
            bonne paire d’après-ski ou mieux, une paire de Sorel Caribou.
          </li>
        </ul>
        <h3>Autres accessoires</h3>
        <p>
          Ayez toujours avec vous <strong>une paire de crampons</strong>{" "}
          (vendues une dizaine d’euros en supermarchés) : elles pourront vous
          éviter des glissades et de bien mauvaises chutes si de la glace est
          présente au sol (ou si elle est cachée sous la neige).
        </p>
        <p>
          <strong>Prévoyez quelques chaufferettes</strong> (mains et pieds
          surtout). Évitez les chaufferettes rechargeables dans l’eau
          bouillante. Vous oublierez de les recharger et elles sont peu
          confortables (elles sont très dures). Les modèles jetables de chez
          décathlon à 10 € la boîte feront parfaitement l’affaire.
        </p>
        <p>
          Enfin, n’oubliez pas l’indispensable <strong>lampe frontale</strong>.
          Choisissez-en une avec <strong>un éclairage rouge</strong> pour
          conserver votre vision nocturne et ne pas éblouir vos camarades
          d’observation.
        </p>
      </Section>
      <Section titre="Équipement photographique">
        <h3>Quel appareil photo choisir ?</h3>
        <p>
          <strong>N’importe quel appareil</strong> permettant de faire des poses
          suffisamment longues peut faire l’affaire. Mais pour ce sujet très
          exigeant, un appareil reflex sera plus performant car souvent moins
          bruité dans les hautes sensibilités. Ne vous inquiétez pas pour le
          froid, en général l’électronique adore ça : je refroidis régulièrement
          mes caméras CCD pour l’astronomie à -30°.
        </p>
        <h3>Quel objectif utiliser ?</h3>
        <p>
          <strong>Un objectif grand angle est idéal</strong>. Compte-tenu de la
          rapidité du sujet, votre objectif doit ouvrir au minimum F2.8. Un
          objectif à F4 peut convenir, mais les draperies des aurores ne seront
          pas figées : vous devrez faire des poses plus longues pour compenser
          le manque de luminosité de l’objectif.
        </p>
        <h3>Cartes mémoires</h3>
        <p>
          Utilisez des cartes mémoires avec suffisamment de capacité (16 go
          minimum, en fonction de votre capteur (20 MP pour le 6D)). Vous
          prendrez en effet beaucoup, beaucoup de photos si la nuit est intense.
          A titre d’exemple, j’ai pris près de 400 photos (en RAW) le soir de la
          tempête solaire du 18/03/2015 (Kp 8.88/9).
        </p>
        <h3>Quels accessoires ?</h3>
        <p>
          Un <strong>trépied</strong> est indispensable, ainsi qu’une{" "}
          <strong>télécommande</strong> pour ne pas faire vibrer l’appareil lors
          du déclenchement (utilisez le retardateur sinon). Pour les panoramas,
          une rotule panoramique vous sera utile. Bien sûr, n’oubliez pas une
          deuxième batterie, votre lampe frontale (avec un éclairage rouge pour
          ne pas vous éblouir). Enfin, couvrez-vous bien, les températures
          peuvent descendent bien en deçà de 0, surtout en Laponie ou en
          Islande.
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesMateriel")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Préparation de votre soirée photographique">
        <p>Avant de partir pour une nuit de veille :</p>
        <ul>
          <li>Vérifiez vos batteries.</li>
          <li>Prenez des boissons chaudes si la nuit s’annonce longue.</li>
          <li>
            Vérifiez vos cartes mémoires : il doit rester suffisamment de place,
            surtout si la soirée s’annonce intense.
          </li>
          <li>
            Surveillez la météo avec votre smartphone (v. l’article «{" "}
            <Lien codePageCible="guideAuroresBoreales">
              guide d’observation des aurores boréales
            </Lien>{" "}
            »).
          </li>
          <li>
            Surveillez les prévisions de Kp pour la soirée (v. l’article «{" "}
            <Lien codePageCible="guideAuroresBoreales">
              guide d’observation des aurores boréales
            </Lien>{" "}
            »).
          </li>
        </ul>
        <p>
          <strong>Important</strong> : après votre soirée, mettez tout de suite
          vos batteries à charger pour être prêt pour le lendemain matin et le
          lendemain soir.
        </p>
      </Section>
      <Section titre="Que faire pendant la nuit ?">
        <p>
          Restez aux aguets d’environ 19h jusqu’à au moins minuit (v. «{" "}
          <Lien codePageCible="guideAuroresBoreales">
            guide d’observation des aurores boréales
          </Lien>{" "}
          »). <strong>Plusieurs ondes de choc devraient se produire</strong> :
          l’aurore boréale va perdre de l’activité, puis va en regagner.
          Dites-vous qu’elles sont capricieuses : il suffit que l’on souhaite
          partir parce qu’il n’y a plus d’activité pour que l’aurore se
          réveille…
        </p>
        <p>
          Aussi, en cas de grosse tempête solaire, il peut arriver que l’aurore
          boréale soit visible alors que le Soleil vient juste de se coucher (il
          peut faire encore jour), jusqu’à très tard dans la nuit. Il m’est
          ainsi arrivé de photographier une aurore en Islande vers 17h30 qui
          s’est achevée vers 5h du matin !
        </p>
        <p>
          Si le froid vous gagne, profitez d’une pause de l’activité aurorale
          pour vous réchauffer : buvez une boisson chaude, bougez, mettez vos
          mains sous vos aisselles.
        </p>
        <p>
          Prenez également régulièrement des nouvelles des autres membres de
          votre groupe.
        </p>
      </Section>
      <Section titre="Après votre nuit…">
        <p>Lorsque vous rentrez dans votre logement :</p>
        <ul>
          <li>
            Laissez votre appareil photo <strong>enfermé</strong> dans votre
            sac. Il se réchauffera ainsi tout doucement. Cela évitera{" "}
            <strong>un choc thermique</strong> qui pourrait être néfaste, et
            évitera la formation de buée sur vos objectifs.
          </li>
          <li>
            <strong>Mettez tout de suite vos batteries à charger</strong> pour
            être prêt dès le lendemain matin (pour votre journée de vacances) et
            pour le lendemain soir.
          </li>
        </ul>
      </Section>
      <Section titre="Où se rendre ?">
        <p>
          Toutes les <strong>régions situées sous le cercle auroral</strong>{" "}
          lors d’une activité moyenne sont idéales (c’est-à-dire à la latitude
          60° - 70° Nord environ). Pour réaliser de belles photographies
          d’aurores boréales, vous devrez ensuite à tout prix vous éloigner des
          villes et de leur pollution lumineuse. En cas d’activité normale, les
          aurores apparaîtront sur les horizons Nord, de l’Ouest à l’Est, ou au
          zénith. Assurez-vous donc qu’au moins quelques-uns de ces horizons
          soient bien éloignés de toute ville importante. Aidez-vous de
          logiciels comme Google Maps ou Google Earth.
        </p>
        <p>
          En Norvège, l’idéal est de trouver une plage orientée au Nord : cet
          horizon sera ainsi vierge de pollution lumineuse. En plus le vert de
          l’aurore se reflètera dans l’eau.
        </p>
        <p>
          <strong>Avertissements</strong> : réalisez votre repérage de jour pour
          vérifier les accès : la nuit la neige bloquera la visibilité et vous
          pouvez vite vous retrouver enneigé ou bloqué par la glace. Attention
          également aux températures la nuit : en Laponie elles peuvent
          descendre sous -30°, ce qui correspond à la température de gel du
          gasoil arctique (-32°). En revenant de votre soirée, vous ne pourrez
          pas redémarrer votre voiture… Enfin, ne vous éloignez pas des routes.
          Si vous empruntez un chemin en voiture vous pouvez vite vous retrouver
          dans l’impossibilité de faire demi-tour et vous retrouver enneigé.
          Allez-y plutôt à pied.
        </p>
      </Section>
      <Section titre="Prise de vue">
        <h3>Réglages</h3>
        <p>
          Vérifiez que votre boîtier est en mode <strong>RAW</strong>. Sinon, la
          compression des images va détruire les étoiles les plus faibles et
          votre photo perdra des détails. Vous perdrez aussi toute la dynamique
          de votre capteur (les images sont encodées sur 12 ou 14 bits en RAW,
          contre 8 bits en JPEG). Il est indispensable de pouvoir utiliser toute
          cette dynamique car les intensités lumineuses des aurores varient très
          fortement.
        </p>
        <p>
          Ouvrez votre objectif au <strong>maximum</strong> si celui-ci ouvre à
          F2.8. Pour un objectif qui ouvre à F1.4 par exemple, vous pouvez vous
          permettre de le fermer un peu : la mise au point sera facilitée et
          vous aurez moins de vignetage. Je ferme mon Samyang 24mm F1.4 à F2.2.
        </p>
        <p>
          Déterminez ensuite le réglage ISO : sur mon EOS 6D, je reste la
          plupart du temps à 1600 ISO.
        </p>
        <p>
          Dernier réglage : le temps de pose. Comme les aurores boréales peuvent
          être très brillantes, et sont soit lentes soit rapides, les temps
          d’expositions à utiliser <strong>varient fortement</strong>. De 2
          secondes pour éviter une surexposition à plus de 10 secondes. L’idée
          générale est de réussir à figer l’aurore pour bien révéler ses
          draperies sur la photo, sans avoir trop de bruit.
        </p>
        <h3>Mise au point</h3>
        <p>
          Réalisez maintenant la mise au point. Attention,{" "}
          <strong>
            la position « infini » de votre objectif est rarement le bon réglage
          </strong>
          . Le meilleur moyen est de viser une source lumineuse brillante et
          très éloignée, comme une étoile brillante (ou à défaut un lampadaire
          lointain). Grossissez l’étoile au maximum avec le liveview. Faites
          ensuite la mise au point manuellement :{" "}
          <strong>l’étoile doit devenir aussi petite que possible</strong>.
        </p>
        <p>
          Faites attention avec les optiques très ouvertes (&lt;2.8) : la mise
          au point est délicate et on se retrouve vite avec une photo floue.
        </p>
        <p>
          Au cours de la nuit, vous manipulerez très fréquemment votre appareil
          car l’aurore boréale se déplace tout le temps dans le ciel ; les
          parties brillantes ne se trouvent donc jamais au même endroit.
          Vérifiez donc très régulièrement votre mise au point, surtout si vous
          disposez d’une optique très ouverte.
        </p>
        <p>Si vous souhaitez avoir un premier plan proche et net :</p>
        <ul>
          <li>
            Faites la mise au point sur ce premier plan en vous aidant d’une
            lampe torche.
          </li>
          <li>
            Privilégiez une optique avec une courte distance focale (ex : 14 mm)
            pour avoir à la fois le premier plan net et l’aurore nette (distance
            d’
            <Lien urlExterne="https://fr.wikipedia.org/wiki/Hyperfocale">
              hyperfocale
            </Lien>{" "}
            comprise entre 2 et 3 mètres à pour un objectif de 14 mm ouvert à
            F2.8, contre 13 mètres environ pour un 24 mm ouvert à F1.4).
          </li>
        </ul>
        <h3>Composition</h3>
        <p>
          Pensez à toujours relier le ciel et la Terre : incluez toujours au
          moins un élément terrestre dans votre composition. Et bien sûr,
          appliquez les règles élémentaires de composition d’une photographie
          (règles des tiers, lignes de forces, etc.).
        </p>
        <Image
          image={obtenirImage(images, "auroresBorealesLephareDAndenes")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Réalisez un timelapse">
        <p>
          Une fois que vous aurez déterminé vos réglages pour la soirée, vous
          pouvez lancer un timelapse pour obtenir un beau souvenir de l’aurore
          boréale que vous aurez vue. Mettez votre appareil en « rafale », et
          verrouillez la prise de vue avec votre télécommande pendant une
          dizaine de minutes. Pendant que votre appareil tourne tout seul, vous
          pourrez profiter du spectacle.
        </p>
        <p>
          La transformation en vidéo se réalise ensuite avec un logiciel comme
          Virtual dub. Certains plugins comme{" "}
          <Lien urlExterne="http://compression.ru/video/motion_estimation/index_en.html">
            MSU Motion Estimation
          </Lien>{" "}
          sont capables de calculer de frames d’interpolation, ce qui permettra
          de ralentir le film. Vous retrouverez ainsi la vitesse de mouvement
          originale de l’aurore boréale. J’ai utilisé cette technique pour le
          timelapse ci-dessous, pris en 2013 :
        </p>
        <div className="conteneur-video">
          <iframe
            src="https://player.vimeo.com/video/60500614"
            frameBorder="0"
            height="100%"
            width="600px"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </Section>
      <Section titre="Réalisez un panoramique">
        <p>
          Pour réaliser une photographie panoramique de qualité, vous devez être
          équipé d’une rotule panoramique. Pendant la prise de vue, veillez à ne
          pas faire de « clics » sur la base (desserrez les freins),{" "}
          <strong>sous peine de faire vibrer l’appareil</strong>. En effet, vous
          devez enchaîner les prises de vue unitaires du panoramique très
          rapidement car l’aurore bouge. Vous n’aurez pas le temps d’attendre
          que l’appareil se stabilise.
        </p>
        <p>
          Vous devrez donc faire des recouvrements à la louche. Rassurez-vous,
          quel que soit le recouvrement réalisé, les logiciels d’assemblages
          panoramiques se débrouilleront très bien. J’utilise l’excellent{" "}
          <Lien urlExterne="https://www.ptgui.com/">PTGui</Lien>.
        </p>
        <p>
          <strong>Attention :</strong>
        </p>
        <ul>
          <li>
            En cas d’aurore boréale très rapide, l’assemblage du panoramique
            deviendra impossible.
          </li>
          <li>
            La réalisation d’un panoramique exige un environnement presque
            vierge de pollution lumineuse autour de vous. Soyez donc exigeant
            avec votre repérage.
          </li>
        </ul>
        <p>
          Le traitement informatique du panoramique sera ensuite similaire à la
          technique utilisée pour réaliser un cliché représentant{" "}
          <Lien codePageCible="photographierArcheVoieLactee">
            l’arche de la Voie lactée
          </Lien>
          .
        </p>
        <Image
          image={obtenirImage(images, "auroreHvitserkur")}
          langue={page.langue}
        />
        <Image
          image={obtenirImage(images, "auroreTaiga")}
          langue={page.langue}
        />
      </Section>
      <Section titre="Réalisez une vidéo">
        <p>
          Les appareils de dernière génération (comme la série des Sony Alpha
          7s) sont maintenant capables de filmer en temps réel les aurores
          boréales. N’ayant encore jamais expérimenté cette discipline, je vous
          invite à consulter les travaux de certains photographes spécialistes
          comme l’excellent{" "}
          <Lien urlExterne="http://www.nuitsacrees.fr/">Stéphane Vetter</Lien>.
        </p>
      </Section>
      <Section titre="Lâchez votre appareil photo… !">
        <p>
          La première fois que j’ai vu une aurore boréale, je me suis précipité
          sur mon appareil photo. L’aurore bougeait à une vitesse incroyable. Le
          temps de déployer le trépied, sortir l’appareil du sac, faire les
          réglages… et l’aurore était terminée. Je m’étais pourtant préparé en
          moins de 5 minutes.{" "}
          <strong>Je n’ai donc même pas profité de cette première fois</strong>.
        </p>
        <p>
          Moralité : si une aurore boréale rapide se déroule au-dessus de vos
          têtes et que votre matériel photo n’est pas prêt, laissez-le là où il
          est et <strong>profitez du spectacle</strong>. Les aurores rapides
          sont souvent très éphémères.
        </p>
        <p>
          Enfin, essayez de prendre des photos aussi machinalement que possible.
          Une fois votre composition et vos réglages faits, lancez des rafales
          pour profiter du spectacle. Votre série de photos vous permettra de
          réaliser un timelapse. Certaines photos seront aussi meilleures que
          d’autres car vous aurez capté ce bref moment pendant lequel l’aurore
          était la plus spectaculaire…
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
